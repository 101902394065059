import { createSlice } from "@reduxjs/toolkit";
import { QuizType } from "../pages/QuizSelect/QuizSelect";

export interface quizResponseSliceState {
  quizType: QuizType.DARK_TRIADS | QuizType.OCEAN | QuizType.MBTI | null;
  quizAnswer: number[];
  attemptedAt: string | null;
}

const initialState: quizResponseSliceState = {
  quizType: null,
  quizAnswer: [],
  attemptedAt: null,
};

export const quizResponseSlice = createSlice({
  name: "quizResponseSlice",
  initialState: initialState,
  reducers: {
    resetQuizRecords: (state) => {
      state.quizType = null;
      state.quizAnswer = [];
      state.attemptedAt = null;
    },
    setQuizRecords: (state, action) => {
      state.quizType = action.payload.quizType;
      state.quizAnswer = action.payload.quizAnswer;
      state.attemptedAt = action.payload.attemptedAt;
    },
  },
});

export const { resetQuizRecords, setQuizRecords } = quizResponseSlice.actions;
export default quizResponseSlice.reducer;
