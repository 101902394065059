import { useNavigate } from "react-router-dom";
import InfoCard, { InfoCardDetails } from "../../components/InfoCard/InfoCard";
import "./QuizSelect.css";

export enum QuizType {
  MBTI = "MBTI",
  OCEAN = "BIG5",
  DARK_TRIADS = "DT",
}

const card_info: InfoCardDetails[] = [
  {
    title: "OCEAN",
    description: "Take the OCEAN quiz to know your personality type.",
    quizType: QuizType.OCEAN,
  },
  {
    title: "Dark Triads",
    description: "Take the Dark Triads quiz to know your personality type.",
    quizType: QuizType.DARK_TRIADS,
  },
  {
    title: "MBTI",
    description: "Take the MBTI quiz to know your personality type.",
    quizType: QuizType.MBTI,
  },
];

const QuizSelect = () => {
  const navigate = useNavigate();
  const handleCardClick = (quizType: string) => {
    navigate("/quiz", { state: { quizType } });
  };
  return (
    <div className="QuizSelect">
      {card_info.map((data, i) => (
        <div key={i}>
          <InfoCard data={data} onClick={handleCardClick} />
        </div>
      ))}
    </div>
  );
};

export default QuizSelect;
