import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import QuizSelect from "./pages/QuizSelect/QuizSelect";
import { useEffect } from "react";
import Login from "./pages/Login/Login";
import Quiz from "./pages/Quiz/Quiz";
import PrivateRoutes from "./utils/RouteValidations/PrivateRoutes";
import { AuthProvider } from "./utils/RouteValidations/AuthContext";
import { useAppDispatch, useAppSelector } from "./utils/redux/hooks";
import Loader from "./components/Loader/Loader";
import { getUserProfile } from "./api/ProfileApi";
import { setUser } from "./slices/userSlice";
import Dashboard from "./pages/Dashboard/Dashboard";
import Report from "./pages/Report/Report";
import NotFound from "./pages/NotFound/NotFound";

function App() {
  const user = useAppSelector((state) => state.user.user);
  const isLoading = useAppSelector((state) => state.loading.loading);
  const dispatch = useAppDispatch();
  const getProfile = async () => {
    const user = await getUserProfile();

    if (!user.error && user.data) {
      dispatch(setUser(user.data));
    }
  };
  useEffect(() => {
    if (!user || user == null) {
      getProfile();
    }
  });

  // disable right clicking
  useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextMenu);

    document.addEventListener("keydown", (event) => {
      if (
        event.key === "F12" ||
        (event.ctrlKey &&
          event.shiftKey &&
          (event.key === "I" || event.key === "J" || event.key === "C")) ||
        (event.ctrlKey && event.key === "U") // Ctrl+U (View Source)
      ) {
        event.preventDefault();
      }
    });
    // Cleanup on component unmount
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <div className="App">
      {isLoading && (
        <div
          className="App-pageLoading"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Loader css={{ color: "#84503d", transform: "scale(2)" }} />
          {/* <Loader css={{ color: "#a07957", transform: "scale(1.5)" }} /> */}
        </div>
      )}
      <div className="App-background">
        <div className="App-background-grid">
          {[...Array(8)].map((_, index) => (
            <div className="App-grid-box" key={index}></div>
          ))}
        </div>
      </div>
      <div className="App-navbar">
        <Navbar />
      </div>
      <div className="App-content">
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route element={<PrivateRoutes />}>
              <Route path="/report" element={<Report />}></Route>
              <Route path="/quizSelect" element={<QuizSelect />}></Route>
              <Route path="/quiz" element={<Quiz />}></Route>
              <Route path="/dashboard" element={<Dashboard />}></Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </div>
    </div>
  );
}

export default App;
