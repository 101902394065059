import "./Navbar.css";
import { ReactComponent as LogoutIco } from "../../assets/icons/logout.svg";
import { Link } from "react-router-dom";
import { logout } from "../../api/AuthApi";
import { useAppDispatch, useAppSelector } from "../../utils/redux/hooks";
import { resetUser } from "../../slices/userSlice";
import { setLoadingFalse, setLoadingTrue } from "../../slices/loadingSlice";

const Navbar = () => {
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const handleLogout = async () => {
    dispatch(setLoadingTrue());
    const data = await logout();
    if (data.error) {
      console.log(data.error);
    }
    dispatch(resetUser());
    dispatch(setLoadingFalse());
  };
  return (
    <div className="Navbar">
      <h1 className="Navbar-heading">
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: "#00000a",
            userSelect: "none",
          }}
        >
          StochastiPersona
        </Link>
      </h1>
      {/* {!user && (
        <div className="Navbar-buttons">
          <button className="Navbar-button1">Sign in</button>
          <button className="Navbar-button2">Sign up</button>
        </div>
      )} */}
      {user && (
        <LogoutIco
          className="Navbar-logoutIco"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleLogout();
          }}
        />
      )}
    </div>
  );
};

export default Navbar;
