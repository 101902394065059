import { createSlice } from "@reduxjs/toolkit";

export interface logoutModalSliceState {
  showLogoutModal: boolean;
}

const initialState: logoutModalSliceState = {
  showLogoutModal: false,
};

export const logoutModalSlice = createSlice({
  name: "logoutModalSlice",
  initialState: initialState,
  reducers: {
    setShowLogoutModalTrue: (state) => {
      state.showLogoutModal = true;
    },
    setShowLogoutModalFalse: (state) => {
      state.showLogoutModal = false;
    },
  },
});

export const { setShowLogoutModalTrue, setShowLogoutModalFalse } =
  logoutModalSlice.actions;
export default logoutModalSlice.reducer;
