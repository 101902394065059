import ApiResponse from "../models/ApiResponse";
import { QuizType } from "../pages/QuizSelect/QuizSelect";
import { supabase } from "../utils/supabase/Supabase";
import SupabaseUtils from "../utils/supabase/SupabaseUtils";

const questionKeyOCEAN: string[] = [
  "ext1",
  "ext2",
  "ext3",
  "ext4",
  "ext5",
  "ext6",
  "ext7",
  "ext8",
  "ext9",
  "ext10",
  "est1",
  "est2",
  "est3",
  "est4",
  "est5",
  "est6",
  "est7",
  "est8",
  "est9",
  "est10",
  "agr1",
  "agr2",
  "agr3",
  "agr4",
  "agr5",
  "agr6",
  "agr7",
  "agr8",
  "agr9",
  "agr10",
  "csn1",
  "csn2",
  "csn3",
  "csn4",
  "csn5",
  "csn6",
  "csn7",
  "csn8",
  "csn9",
  "csn10",
  "opn1",
  "opn2",
  "opn3",
  "opn4",
  "opn5",
  "opn6",
  "opn7",
  "opn8",
  "opn9",
  "opn10",
];
const questionKeyDarkTriads: string[] = [
  "m1",
  "m2",
  "m3",
  "m4",
  "m5",
  "m6",
  "m7",
  "m8",
  "m9",
  "n1",
  "n2",
  "n3",
  "n4",
  "n5",
  "n6",
  "n7",
  "n8",
  "n9",
  "p1",
  "p2",
  "p3",
  "p4",
  "p5",
  "p6",
  "p7",
  "p8",
  "p9",
];
interface setQuizResponseParams {
  quizType: string;
  quizAnswers: number[];
}
async function postQuizResponse({
  quizType,
  quizAnswers,
}: setQuizResponseParams): Promise<ApiResponse<Boolean>> {
  const keys =
    quizType === QuizType.OCEAN
      ? questionKeyOCEAN
      : QuizType.DARK_TRIADS
      ? questionKeyDarkTriads
      : [...questionKeyOCEAN, ...questionKeyDarkTriads];

  try {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (user == null) {
      throw new Error("User not logged in.");
    }

    // for quizType mbti and ocean
    if (quizType === QuizType.OCEAN || quizType === QuizType.DARK_TRIADS) {
      const result = keys.reduce(
        (acc: { [key: string]: number }, key, index) => {
          acc[key] = quizAnswers[index];
          return acc;
        },
        {}
      );
      const { data, error } = await supabase
        .from(
          quizType === QuizType.OCEAN
            ? SupabaseUtils.RESPONSES_OCEAN_TABLE
            : SupabaseUtils.RESPONSES_DARK_TRIADS_TABLE
        )
        .insert([{ profile_id: user.id, ...result }]);

      if (error) {
        throw new Error(error.message);
      }
    }
    if (quizType === QuizType.MBTI) {
      const result1: { [key: string]: number } = {};
      const result2: { [key: string]: number } = {};

      for (let i = 0; i < questionKeyOCEAN.length; i++) {
        result1[questionKeyOCEAN[i]] = quizAnswers[i]; // Map each key to the corresponding value
      }
      for (let i = 0; i < questionKeyDarkTriads.length; i++) {
        result2[questionKeyDarkTriads[i]] = quizAnswers[50 + i]; // Map each key to the corresponding value
      }

      const { data, error } = await supabase
        .from(SupabaseUtils.RESPONSES_OCEAN_TABLE)
        .insert([{ profile_id: user.id, ...result1 }]);

      if (error) {
        throw new Error(error.message);
      }
      const { data: secondData, error: secondError } = await supabase
        .from(SupabaseUtils.RESPONSES_DARK_TRIADS_TABLE)
        .insert([{ profile_id: user.id, ...result2 }]);

      if (secondError) {
        throw new Error(secondError.message);
      }
    }
    return { data: true };
  } catch (e) {
    if (e instanceof Error) {
      return { error: e.message };
    }
    return {};
  }
}

export { postQuizResponse };
