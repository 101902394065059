import "./Loader.css";

type Props = {
  css?: React.CSSProperties;
};
const Loader = (props: Props) => {
  return <div className="Loader" style={props.css}></div>;
};

export default Loader;
