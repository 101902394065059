import "./ProgressBar.css";

type Props = {
  min?: number;
  value: number;
  max?: number;
  color?: string;
};
const ProgressBar = (props: Props) => {
  return (
    <div className="ProgressBar">
      <span className="ProgressBar-min">{props.min ? props.min : 0}</span>
      <div className="ProgressBar-bar">
        <span className="ProgressBar-bar-value">
          Ques. attempted : {props.value}
        </span>
        <progress
          className="ProgressBar-bar-bar"
          value={props.value}
          max={props.max ? props.max : 1}
        />
      </div>
      <span className="ProgressBar-max">{props.max ? props.max : 1}</span>
    </div>
  );
};

export default ProgressBar;
