import "./InfoCard.css";
import img1 from "../../assets/images/BIG5.jpg";
import img2 from "../../assets/images/DT.jpg";
import img3 from "../../assets/images/MBTI.jpg";
import { QuizType } from "../../pages/QuizSelect/QuizSelect";
export interface InfoCardDetails {
  title: string;
  description: string;
  quizType: string;
}

type Props = {
  data: InfoCardDetails;
  onClick?: (quizType: string) => void;
};
const InfoCard = (props: Props) => {
  return (
    <div className="InfoCard">
      <div className="InfoCard-img-container">
        <img
          src={
            props.data.quizType === QuizType.OCEAN
              ? img1
              : props.data.quizType === QuizType.DARK_TRIADS
              ? img2
              : img3
          }
          alt="info-card"
          className="InfoCard-img"
        />
      </div>
      <div className="InfoCard-title">
        <h1>{props.data.title}</h1>
      </div>
      <div className="InfoCard-info">
        <p>{props.data.description}</p>
      </div>
      <div className="InfoCard-button">
        <button
          className="InfoCard-btn"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (props.onClick) {
              props.onClick(props.data.quizType);
            }
          }}
        >
          Take Quiz
        </button>
      </div>
    </div>
  );
};

export default InfoCard;
