import { useEffect, useState } from "react";
import QuizResult from "../../models/QuizResult";
import "./Dashboard.css";
import { getQuizResults } from "../../api/ResultsApi";
import { useNavigate } from "react-router-dom";
import { QuizType } from "../QuizSelect/QuizSelect";

const Dashboard = () => {
  const navigate = useNavigate();
  const [results, setResults] = useState<QuizResult[]>([]);
  const getResults = async () => {
    const res = await getQuizResults();
    if (!res.error && res.data) {
      setResults(res.data);
    }
  };
  useEffect(() => {
    // get the results
    getResults();
    console.log(results);
  }, []);
  return (
    <div className="Dashboard">
      <div className="Dashboard-card">
        <h1 style={{ marginBottom: "1.5rem" }}>Previous Results :</h1>
        <div className="Dashboard-results">
          {results.length === 0 && (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h2>No Previous Records</h2>
            </div>
          )}
          {results.length > 0 && (
            <>
              <div className="Dashboard-head">
                <div style={{ fontSize: "1.75rem" }}>QuizType</div>
                <div style={{ fontSize: "1.75rem" }}>Attempted On</div>
              </div>
              <div className="Dashboard-results-list">
                {results.map((result, index) => (
                  <div
                    className="Dashboard-result"
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate("/report", {
                        state: {
                          quizType: result.quizType,
                          attemptedAt: result.start_time,
                          aggr: result.aggr,
                          data: result.data,
                        },
                      });
                    }}
                  >
                    <div style={{ fontSize: "1.5rem" }}>
                      {result.quizType == QuizType.OCEAN
                        ? "OCEAN"
                        : result.quizType == QuizType.DARK_TRIADS
                        ? "Dark Triads"
                        : "MBTI"}
                    </div>
                    <div style={{ fontSize: "1.5rem" }}>{"02-12-2024"} </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
