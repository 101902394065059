import "./NotFound.css";
import notfound from "../../assets/images/404 Not Found.jpeg";
import { useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="NotFound">
      <img src={notfound} alt="" />
      <button
        className="NotFound-button"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          navigate("/", { replace: true });
        }}
      >
        Go to Home Page
      </button>
    </div>
  );
};

export default NotFound;
