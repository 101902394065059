import ApiResponse from "../models/ApiResponse";
import Profile, { profileCopyWith } from "../models/Profile";
import { supabase } from "../utils/supabase/Supabase";
import SupabaseUtils from "../utils/supabase/SupabaseUtils";

async function getUserProfile(): Promise<ApiResponse<Profile>> {
  try {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (user == null) {
      throw new Error("User not logged in.");
    }

    //reading data from profiles table
    const { data, error } = await supabase
      .from(SupabaseUtils.PROFILES_TABLE)
      .select("*")
      .eq("id", user.id);
    if (error) {
      throw new Error(error.message);
    }
    let profile: Profile;
    if (data != null && data.length > 0) {
      profile = profileCopyWith(data[0]);

      return { data: profile };
    }
  } catch (e) {
    if (e instanceof Error) {
      return { error: e.message };
    }
    return {};
  }
  return {};
}

export { getUserProfile };
