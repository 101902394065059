import "./Quiz.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { QuizType } from "../QuizSelect/QuizSelect";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { useAppDispatch, useAppSelector } from "../../utils/redux/hooks";
import { setQuizRecords } from "../../slices/quizResponseSlice";
import { postQuizResponse } from "../../api/QuizResponse";
import { setLoadingFalse, setLoadingTrue } from "../../slices/loadingSlice";
import { postQuizResult } from "../../api/ResultsApi";

export interface Question {
  type: string;
  question: string;
}
const OCEAN_QUESTIONS: Question[] = [
  { type: "EXT", question: "I am the life of the party." },
  { type: "EXT", question: "I don't talk a lot." },
  { type: "EXT", question: "I feel comfortable around people." },
  { type: "EXT", question: "I keep in the background." },
  { type: "EXT", question: "I start conversations." },
  { type: "EXT", question: "I have little to say." },
  { type: "EXT", question: "I talk to a lot of different people at parties." },
  { type: "EXT", question: "I don't like to draw attention to myself." },
  { type: "EXT", question: "I don't mind being the center of attention." },
  { type: "EXT", question: "I am quiet around strangers." },
  { type: "EST", question: "I get stressed out easily." },
  { type: "EST", question: "I am relaxed most of the time." },
  { type: "EST", question: "I worry about things." },
  { type: "EST", question: "I seldom feel blue." },
  { type: "EST", question: "I am easily disturbed." },
  { type: "EST", question: "I get upset easily." },
  { type: "EST", question: "I change my mood a lot." },
  { type: "EST", question: "I have frequent mood swings." },
  { type: "EST", question: "I get irritated easily." },
  { type: "EST", question: "I often feel blue." },
  { type: "AGR", question: "I feel little concern for others." },
  { type: "AGR", question: "I am interested in people." },
  { type: "AGR", question: "I insult people." },
  { type: "AGR", question: "I sympathize with others' feelings." },
  { type: "AGR", question: "I am not interested in other people's problems." },
  { type: "AGR", question: "I have a soft heart." },
  { type: "AGR", question: "I am not really interested in others." },
  { type: "AGR", question: "I take time out for others." },
  { type: "AGR", question: "I feel others' emotions." },
  { type: "AGR", question: "I make people feel at ease." },
  { type: "CSN", question: "I am always prepared." },
  { type: "CSN", question: "I leave my belongings around." },
  { type: "CSN", question: "I pay attention to details." },
  { type: "CSN", question: "I make a mess of things." },
  { type: "CSN", question: "I get chores done right away." },
  {
    type: "CSN",
    question: "I often forget to put things back in their proper place.",
  },
  { type: "CSN", question: "I like order." },
  { type: "CSN", question: "I shirk my duties." },
  { type: "CSN", question: "I follow a schedule." },
  { type: "CSN", question: "I am exacting in my work." },
  { type: "OPN", question: "I have a rich vocabulary." },
  { type: "OPN", question: "I have difficulty understanding abstract ideas." },
  { type: "OPN", question: "I have a vivid imagination." },
  { type: "OPN", question: "I am not interested in abstract ideas." },
  { type: "OPN", question: "I have excellent ideas." },
  { type: "OPN", question: "I do not have a good imagination." },
  { type: "OPN", question: "I am quick to understand things." },
  { type: "OPN", question: "I use difficult words." },
  { type: "OPN", question: "I spend time reflecting on things." },
  { type: "OPN", question: "I am full of ideas." },
];

const DARK_TRIAD_QUESTIONS: Question[] = [
  { type: "M", question: "It's not wise to tell your secrets." },
  { type: "M", question: "I like to use clever manipulation to get my way." },
  {
    type: "M",
    question:
      "Whatever it takes, you must get the important people on your side.",
  },
  {
    type: "M",
    question:
      "Avoid direct conflict with others because they may be useful in the future.",
  },
  {
    type: "M",
    question:
      "It's wise to keep track of information that you can use against people later.",
  },
  {
    type: "M",
    question: "You should wait for the right time to get back at people.",
  },
  {
    type: "M",
    question:
      "There are things you should hide from other people because they don't need to know.",
  },
  { type: "M", question: "Make sure your plans benefit you, not others." },
  { type: "M", question: "Most people can be manipulated." },
  { type: "N", question: "People see me as a natural leader." },
  { type: "N", question: "I hate being the center of attention." },
  { type: "N", question: "Many group activities tend to be dull without me." },
  {
    type: "N",
    question: "I know that I am special because everyone keeps telling me so.",
  },
  { type: "N", question: "I like to get acquainted with important people." },
  { type: "N", question: "I feel embarrassed if someone compliments me." },
  { type: "N", question: "I have been compared to famous people." },
  { type: "N", question: "I am an average person." },
  { type: "N", question: "I insist on getting the respect I deserve." },
  { type: "P", question: "I like to get revenge on authorities." },
  { type: "P", question: "I avoid dangerous situations." },
  { type: "P", question: "Payback needs to be quick and nasty." },
  { type: "P", question: "People often say I'm out of control." },
  { type: "P", question: "It's true that I can be mean to others." },
  { type: "P", question: "People who mess with me always regret it." },
  { type: "P", question: "I have never gotten into trouble with the law." },
  { type: "P", question: "I enjoy having sex with people I hardly know." },
  { type: "P", question: "I'll say anything to get what I want." },
];

const t1: string[] = [
  "EXT1",
  "EXT2",
  "EXT3",
  "EXT4",
  "EXT5",
  "EXT6",
  "EXT7",
  "EXT8",
  "EXT9",
  "EXT10",
  "EST1",
  "EST2",
  "EST3",
  "EST4",
  "EST5",
  "EST6",
  "EST7",
  "EST8",
  "EST9",
  "EST10",
  "AGR1",
  "AGR2",
  "AGR3",
  "AGR4",
  "AGR5",
  "AGR6",
  "AGR7",
  "AGR8",
  "AGR9",
  "AGR10",
  "CSN1",
  "CSN2",
  "CSN3",
  "CSN4",
  "CSN5",
  "CSN6",
  "CSN7",
  "CSN8",
  "CSN9",
  "CSN10",
  "OPN1",
  "OPN2",
  "OPN3",
  "OPN4",
  "OPN5",
  "OPN6",
  "OPN7",
  "OPN8",
  "OPN9",
  "OPN10",
];

const t2: string[] = [
  "M1",
  "M2",
  "M3",
  "M4",
  "M5",
  "M6",
  "M7",
  "M8",
  "M9",
  "N1",
  "N2",
  "N3",
  "N4",
  "N5",
  "N6",
  "N7",
  "N8",
  "N9",
  "P1",
  "P2",
  "P3",
  "P4",
  "P5",
  "P6",
  "P7",
  "P8",
  "P9",
];
const Quiz = () => {
  //to show quit quiz modal
  const [showModal, setShowModal] = useState<boolean>(false);
  //to navigate
  const navigate = useNavigate();
  const location = useLocation();
  //get quiz type from previous page
  const { state } = location as { state: { quizType: string } };

  //dispatcher
  const dispatch = useAppDispatch();

  //state variables
  const [questionCount, setQuestionCount] = useState<number>(0);
  const [questions, setQuestions] = useState<string[]>([]);
  const [answer, setAnswer] = useState<number[]>([]);
  const [startTime, setStartTime] = useState<Date>(new Date());
  const [autoNext, setAutoNext] = useState<boolean>(true);
  const [isAnimating, setIsAnimating] = useState(false);

  //nav to next question
  const handleNext = () => {
    if (questionCount + 1 === answer.length) {
      setAutoNext(true);
    }
    if (questionCount < questions.length - 1) {
      setIsAnimating(true); // Start the animation
      setTimeout(() => {
        setQuestionCount(questionCount + 1);
        setIsAnimating(false); // End the animation
      }, 500);
    }
  };

  //nav to previous question
  const handlePrevious = () => {
    if (questionCount > 0) {
      setIsAnimating(true); // Start the animation
      setTimeout(() => {
        setQuestionCount(questionCount - 1);
        setIsAnimating(false); // End the animation
      }, 500);
    }
    setAutoNext(false);
  };

  //option selection
  const selectOption = (option: number) => {
    if (answer.length === 0) {
      setAnswer([option]);
    } else if (questionCount === answer.length) {
      const newAnswer = [...answer];
      newAnswer[questionCount] = option;
      setAnswer(newAnswer);
    } else if (questionCount < answer.length) {
      const newAnswer = [...answer];
      newAnswer[questionCount] = option;
      setAnswer(newAnswer);
    }
    //next question
    if (autoNext) {
      handleNext();
    }
  };

  const quiz = useAppSelector((state) => state.quizResponse);
  //submit quiz
  const handleQuizSubmit = async () => {
    dispatch(setLoadingTrue()); // Show
    dispatch(
      setQuizRecords({
        quizType: state.quizType,
        quizAnswer: answer,
        attemptedAt: startTime.toISOString(),
      })
    );
    let resData: string = "";
    const username = "swaraj"; // Replace with your username
    const password = "Researcher@123."; // Replace with your password
    const credentials = btoa(`${username}:${password}`); // Encode credentials in Base64
    const body = {
      type: state.quizType,
      columns:
        state.quizType === QuizType.OCEAN
          ? t1
          : state.quizType === QuizType.DARK_TRIADS
          ? t2
          : [...t1, ...t2],
      data: answer,
    };
    await fetch("https://api.aitmltaskv3.online/predict", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${credentials}`, // Add Basic Authentication header
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(setLoadingFalse()); // Hide the loader after completion
        if (data.error) {
          // Display error message
          console.log(data.error);
        } else {
          // Set the result
          if (data.Prediction) {
            resData = data.Prediction;
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    // navigate("/report", { replace: true });
    const aggr: number[] = [];
    if (state.quizType === QuizType.OCEAN) {
      for (let i = 0; i < 5; i++) {
        let sum = 0;
        for (let j = 0; j < 10; j++) {
          sum += answer[i * 10 + j];
        }
        aggr.push(sum);
      }
    }
    if (state.quizType === QuizType.DARK_TRIADS) {
      for (let i = 0; i < 3; i++) {
        let sum = 0;
        for (let j = 0; j < 9; j++) {
          sum += answer[i * 9 + j];
        }
        aggr.push(sum);
      }
    }
    if (state.quizType === QuizType.MBTI) {
      for (let i = 0; i < 5; i++) {
        let sum = 0;
        for (let j = 0; j < 10; j++) {
          sum += answer[i * 10 + j];
        }
        aggr.push(sum);
      }
      for (let i = 0; i < 3; i++) {
        let sum = 0;
        for (let j = 0; j < 9; j++) {
          sum += answer[i * 9 + j + 50];
        }
        aggr.push(sum);
      }
    }
    const res = await postQuizResponse({
      quizType: state.quizType,
      quizAnswers: answer,
    });
    //send data to supabase and reset state
    if (res.data) {
      const uploadRes = await postQuizResult({
        quizType: state.quizType,
        dataQ: resData,
        aggr: aggr,
        attemptedOn: startTime.toISOString(),
      });
      console.log("uploadRes", uploadRes);
      navigate("/report", {
        state: {
          quizType: state.quizType,
          attemptedAt: startTime,
          aggr: aggr,
          data: resData,
        },
      });
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (["1", "2", "3", "4", "5"].includes(event.key)) {
      selectOption(parseInt(event.key));
    }
    if (
      event.key === "ArrowRight" &&
      !(
        answer.length === 0 ||
        questionCount === questions.length - 1 ||
        answer.length === questionCount
      )
    ) {
      handleNext();
    }
    if (event.key === "ArrowLeft" && questionCount !== 0) {
      handlePrevious();
    }
  };

  //keyboard event listener
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
  });

  //set questions based on quiz type
  useEffect(() => {
    if (state.quizType === QuizType.OCEAN) {
      setQuestions(OCEAN_QUESTIONS.map((q) => q.question));
    } else if (state.quizType === QuizType.DARK_TRIADS) {
      setQuestions(DARK_TRIAD_QUESTIONS.map((q) => q.question));
    } else if (state.quizType === QuizType.MBTI) {
      setQuestions(
        [...OCEAN_QUESTIONS, ...DARK_TRIAD_QUESTIONS].map((q) => q.question)
      );
    }
  }, [state.quizType]);

  //listen to back button press
  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      setShowModal(true);
      window.history.replaceState(null, "", window.location.href);
    };
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  return (
    <div className="Quiz">
      <div className="Quiz-card">
        <div className="Quiz-quiz-type">
          <h1>
            {state.quizType === QuizType.OCEAN
              ? "OCEAN"
              : state.quizType === QuizType.MBTI
              ? "MBTI"
              : "DARK Triads"}
          </h1>
          <div className="Quiz-quiz-progress">
            <ProgressBar value={answer.length} min={0} max={questions.length} />
          </div>
        </div>
        <div className="Quiz-quiz-area">
          <div
            className={`Quiz-quiz-area-question ${
              isAnimating ? "fade-out" : "fade-in"
            }`}
          >
            <h2>
              Q{questionCount + 1}. {questions[questionCount]}
            </h2>
          </div>
          <div
            className={`Quiz-quiz-area-options ${
              isAnimating ? "fade-out" : "fade-in"
            }`}
          >
            <div
              className="Quiz-quiz-area-option"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                selectOption(1);
              }}
              onKeyDown={(e) => {
                if (e.key === "1") {
                  console.log(e);

                  e.preventDefault();
                  selectOption(1);
                }
              }}
              style={
                questionCount <= answer.length && answer[questionCount] === 1
                  ? { backgroundColor: "#a07957", color: "white" }
                  : {}
              }
            >
              <div className="Quiz-quiz-area-option-number">1</div>
              <div className="Quiz-quiz-area-option-txt">Strongly Disagree</div>
            </div>
            <div
              className="Quiz-quiz-area-option"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                selectOption(2);
              }}
              onKeyDown={(e) => {
                if (e.key === "2") {
                  console.log(e);

                  e.preventDefault();
                  selectOption(2);
                }
              }}
              style={
                questionCount <= answer.length && answer[questionCount] === 2
                  ? { backgroundColor: "#a07957", color: "white" }
                  : {}
              }
            >
              <div className="Quiz-quiz-area-option-number">2</div>
              <div className="Quiz-quiz-area-option-txt">Disagree</div>
            </div>
            <div
              className="Quiz-quiz-area-option"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                selectOption(3);
              }}
              onKeyDown={(e) => {
                if (e.key === "3") {
                  console.log(e);

                  e.preventDefault();
                  selectOption(3);
                }
              }}
              style={
                questionCount <= answer.length && answer[questionCount] === 3
                  ? { backgroundColor: "#a07957", color: "white" }
                  : {}
              }
            >
              <div className="Quiz-quiz-area-option-number">3</div>
              <div className="Quiz-quiz-area-option-txt">Neutral</div>
            </div>
            <div
              className="Quiz-quiz-area-option"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                selectOption(4);
              }}
              onKeyDown={(e) => {
                if (e.key === "4") {
                  console.log(e);

                  e.preventDefault();
                  selectOption(4);
                }
              }}
              style={
                questionCount <= answer.length && answer[questionCount] === 4
                  ? { backgroundColor: "#a07957", color: "white" }
                  : {}
              }
            >
              <div className="Quiz-quiz-area-option-number">4</div>
              <div className="Quiz-quiz-area-option-txt">Agree</div>
            </div>
            <div
              className="Quiz-quiz-area-option"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                selectOption(5);
              }}
              onKeyDown={(e) => {
                if (e.key === "5") {
                  console.log(e);

                  e.preventDefault();
                  selectOption(5);
                }
              }}
              style={
                questionCount <= answer.length && answer[questionCount] === 5
                  ? { backgroundColor: "#a07957", color: "white" }
                  : {}
              }
            >
              <div className="Quiz-quiz-area-option-number">5</div>
              <div className="Quiz-quiz-area-option-txt">Strongly Agree</div>
            </div>
          </div>
          <div className="Quiz-quiz-area-count"></div>
        </div>
        <div className="Quiz-quiz-nav-btns">
          <button
            className="Quiz-quiz-nav-btn"
            onClick={handlePrevious}
            style={questionCount === 0 ? { opacity: "0.5" } : {}}
            disabled={questionCount === 0 ? true : false}
          >
            ← Previous
          </button>
          <button
            className="Quiz-quiz-nav-btn"
            onClick={handleNext}
            style={
              answer.length === 0 ||
              questionCount === questions.length - 1 ||
              answer.length === questionCount
                ? { opacity: "0.5" }
                : {}
            }
            disabled={
              answer.length === 0 ||
              questionCount === questions.length - 1 ||
              answer.length === questionCount
                ? true
                : false
            }
          >
            Next →
          </button>
        </div>
      </div>
      <div className="Quiz-final-btns">
        {answer.length === questions.length && (
          <button
            className="Quiz-btn-submit"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleQuizSubmit();
            }}
          >
            Submit Quiz
          </button>
        )}
        <button
          className="Quiz-btn-end"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowModal(true);
          }}
        >
          Quit Quiz
        </button>
      </div>
      {showModal && (
        <div
          className="Quiz-modal"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowModal(false);
          }}
        >
          <div className="Quiz-modal-card">
            <div>
              <p>Are you sure you want to quit?</p>
              <p style={{ marginBottom: "1.25rem" }}>
                The attempt will not be saved.
              </p>
            </div>
            <div className="Quiz-modal-btns">
              <button
                type="button"
                className="Quiz-modal-btn-y"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowModal(false);
                  window.history.replaceState(
                    null,
                    "Select Page",
                    "/quizselect"
                  );
                  navigate("/quizselect");
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="Quiz-modal-btn-n"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowModal(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Quiz;
