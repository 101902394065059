import { useLocation, useNavigate } from "react-router-dom";
import "./Report.css";
import RadarChart from "../../components/RadarChart/RadarChart";
import { QuizType } from "../QuizSelect/QuizSelect";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../utils/redux/hooks";
import { setLoadingFalse } from "../../slices/loadingSlice";

const OCEAN = [
  "Openness",
  "Conscientiousness",
  "Extraversion",
  "Agreeableness",
  "Neuroticism",
];
const DT = ["Machiavellianism", "Narcissism", "Psychopathy"];
const Report = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [mbtiView, setMbtiView] = useState<number>(0);
  const { state } = location as {
    state: {
      quizType: string;
      attemptedAt: string;
      aggr: number[];
      data?: string;
    };
  };
  const handleViewCHange = (c: number) => {
    if (c === 0) {
      if (mbtiView !== 0) {
        setMbtiView(mbtiView - 1);
      }
    }
    if (c === 1) {
      if (mbtiView !== 2) {
        setMbtiView(mbtiView + 1);
      }
    }
  };

  useEffect(() => {
    dispatch(setLoadingFalse());
  }, [state]);
  return (
    <div className="Report">
      <div className="Report-card">
        <h1 className="Report-card-heading">
          Your
          {state.quizType === QuizType.OCEAN
            ? " OCEAN "
            : state.quizType === QuizType.DARK_TRIADS
            ? " Dark Triads "
            : " MBTI "}
          Personality Test Report
        </h1>
        {state.quizType === QuizType.OCEAN && (
          <div className="Report-card-data-ocean">
            <div className="Report-card-charts-ocean">
              <div className="Report-card-chart-ocean">
                <RadarChart data={state.aggr} quizType={QuizType.OCEAN} />
              </div>
              <div className="Report-card-chart-ocean-data">
                <h2 style={{ lineHeight: "3rem" }}> Prominent Trait : </h2>
                <h3 style={{ color: "#3696e6" }}>
                  {state.data} ({Math.max(...state.aggr)})
                </h3>
              </div>
            </div>
          </div>
        )}
        {state.quizType === QuizType.DARK_TRIADS && (
          // state.aggrOcean && state.aggrDT && (
          <div className="Report-card-data-ocean">
            <div className="Report-card-charts-ocean">
              <div className="Report-card-chart-ocean">
                <RadarChart data={state.aggr} quizType={QuizType.DARK_TRIADS} />
              </div>
              <div className="Report-card-chart-ocean-data">
                <h2 style={{ lineHeight: "4rem" }}> Egregious Trait : </h2>
                <h3 style={{ color: "#e42525" }}>
                  {state.data} ({Math.max(...state.aggr)})
                </h3>
              </div>
            </div>
          </div>
        )}
        {state.quizType === QuizType.MBTI && (
          // state.aggrOcean && state.aggrDT && (
          <div className="Report-card-data-ocean">
            {mbtiView === 0 && (
              <div className="Report-card-charts-ocean">
                <div className="Report-card-chart-ocean">
                  <RadarChart
                    data={state.aggr.slice(0, 5)}
                    quizType={QuizType.OCEAN}
                  />
                </div>
                <>
                  <div className="Report-card-chart-ocean-data">
                    <h2 style={{ lineHeight: "3rem" }}> Prominent Trait : </h2>
                    <h3 style={{ color: "#3696e6" }}>
                      {
                        OCEAN[
                          state.aggr
                            .slice(0, 5)
                            .indexOf(Math.max(...state.aggr.slice(0, 5)))
                        ]
                      }
                      ({Math.max(...state.aggr.slice(0, 5))})
                    </h3>
                  </div>
                  <div style={{ display: "flex" }}>
                    <button
                      className="Report-button-b"
                      onClick={() => handleViewCHange(0)}
                    >
                      ←View Prev
                    </button>
                    <button
                      className="Report-button"
                      onClick={() => handleViewCHange(1)}
                    >
                      View Next →
                    </button>
                  </div>
                </>
              </div>
            )}
            {mbtiView === 1 && (
              <div className="Report-card-charts-ocean">
                <div className="Report-card-chart-ocean">
                  <RadarChart
                    data={state.aggr.slice(-3)}
                    quizType={QuizType.DARK_TRIADS}
                  />
                </div>
                <>
                  <div className="Report-card-chart-ocean-data">
                    <h2 style={{ lineHeight: "4rem" }}> Egregious Trait : </h2>
                    <h3 style={{ color: "#e42525" }}>
                      {
                        DT[
                          state.aggr
                            .slice(-3)
                            .indexOf(Math.max(...state.aggr.slice(-3)))
                        ]
                      }{" "}
                      ({Math.max(...state.aggr.slice(-3))})
                    </h3>
                  </div>
                  <div style={{ display: "flex" }}>
                    <button
                      className="Report-button-b"
                      onClick={() => handleViewCHange(0)}
                    >
                      ←View Prev
                    </button>
                    <button
                      className="Report-button"
                      onClick={() => handleViewCHange(1)}
                    >
                      View Next →
                    </button>
                  </div>
                </>
              </div>
            )}
            {mbtiView === 2 && (
              <div className="Report-card-charts-ocean">
                <>
                  <div>{state.data}</div>
                  <div style={{ display: "flex" }}>
                    <button
                      className="Report-button-b"
                      onClick={() => handleViewCHange(0)}
                    >
                      ←View Prev
                    </button>
                    <button
                      className="Report-button"
                      onClick={() => handleViewCHange(1)}
                    >
                      View Next →
                    </button>
                  </div>
                </>
              </div>
            )}
          </div>
        )}
      </div>
      <button
        className="Report-button-back"
        onClick={() => {
          navigate("/dashboard", { replace: true });
        }}
      >
        Go to Dashboard
      </button>
    </div>
  );
};

export default Report;
