import ApiResponse from "../models/ApiResponse";
import QuizResult, { quizResultCopyWith } from "../models/QuizResult";
import { QuizType } from "../pages/QuizSelect/QuizSelect";
import { supabase } from "../utils/supabase/Supabase";
import SupabaseUtils from "../utils/supabase/SupabaseUtils";

interface postQuizResultParams {
  quizType: string;
  attemptedOn: string;
  aggr: number[];
  dataQ: string;
}
async function postQuizResult({
  quizType,
  attemptedOn,
  aggr,
  dataQ,
}: postQuizResultParams): Promise<ApiResponse<Boolean>> {
  try {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (user == null) {
      throw new Error("User not logged in.");
    }

    const { data, error } = await supabase
      .from(SupabaseUtils.QUIZ_RESULTS_TABLE)
      .insert([
        {
          profile_id: user.id,
          quiz_type: quizType,
          attempted_on: attemptedOn,
          aggr: aggr,
          data: dataQ,
        },
      ]);

    if (error) {
      throw new Error(error.message);
    }

    return { data: true };
  } catch (e) {
    if (e instanceof Error) {
      return { error: e.message };
    }
    return {};
  }
}

async function getQuizResults(): Promise<ApiResponse<QuizResult[]>> {
  try {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (user == null) {
      throw new Error("User not logged in.");
    }

    //reading data from profiles table
    const { data, error } = await supabase
      .from(SupabaseUtils.QUIZ_RESULTS_TABLE)
      .select("*")
      .eq("profile_id", user.id);
    console.log("data", data);

    if (error) {
      throw new Error(error.message);
    }
    let quizResults: QuizResult[] = [];
    if (data != null && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        quizResults.push(quizResultCopyWith(data[i]));
      }
      return { data: quizResults };
    }
  } catch (e) {
    if (e instanceof Error) {
      return { error: e.message };
    }
    return {};
  }
  return {};
}

export { postQuizResult, getQuizResults };
