import "./Login.css";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { emailLogin } from "../../api/AuthApi";
import { ReactComponent as EmailIco } from "../../assets/icons/email.svg";
import { ReactComponent as PasswdIco } from "../../assets/icons/lock.svg";
import { ReactComponent as EyeClosed } from "../../assets/icons/passwd-hidden.svg";
import { ReactComponent as EyeOpen } from "../../assets/icons/passwd-show.svg";
import { ReactComponent as GoogleIcon } from "../../assets/icons/googleIco.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/icons/linkedInIco.svg";
import { getUserProfile } from "../../api/ProfileApi";
import { useAppDispatch } from "../../utils/redux/hooks";
import { setUser } from "../../slices/userSlice";
import Loader from "../../components/Loader/Loader";
const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo = (location.state as any)?.from?.pathname || "/";

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const getProfile = async () => {
    const user = await getUserProfile();
    if (!user.error && user.data) {
      dispatch(setUser(user.data));
    }
  };

  const handleEmailLogin = async () => {
    setLoading(true);
    if (!email || !password) {
      setError("Email or Password is missing");
    } else {
      const response = await emailLogin({ email: email, password: password });
      if (response.error) {
        console.log(response.error);
        setError(response.error);
      } else if (response.data) {
        setError("");
        await getProfile();
        setLoading(false);
        navigate(redirectTo, { replace: true });
      }
    }
    setLoading(false);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  return (
    <div className="Login">
      <div className="Login-card">
        <div className="Login-card-l">
          <h1 className="Login-card-l-heading">Sign in</h1>

          <div className="Login-fields">
            <div className="Login-email">
              <EmailIco className="Login-emailico" />
              <input
                type="text"
                id="username"
                placeholder="Email"
                onChange={handleEmailChange}
                required
              />
            </div>
            <div className="Login-passwd">
              <PasswdIco className="Login-passwdico" />
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Password"
                onChange={handlePasswordChange}
                required
              />
              {showPassword && (
                <EyeOpen
                  className="Login-eye"
                  onClick={() => setShowPassword(false)}
                />
              )}
              {!showPassword && (
                <EyeClosed
                  className="Login-eye"
                  onClick={() => setShowPassword(true)}
                />
              )}
            </div>
            <div className="Login-button">
              <button
                className="Login-btn"
                onClick={(e) => {
                  if (loading) return;
                  e.preventDefault();
                  e.stopPropagation();
                  handleEmailLogin();
                }}
              >
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "2.5rem",
                    }}
                  >
                    <Loader css={{ transform: "scale(0.5)", color: "#fff" }} />
                  </div>
                ) : (
                  "Sign in"
                )}
              </button>
              <div
                className="Login-error"
                style={{
                  visibility: error ? "visible" : "hidden",
                  color: "red",
                }}
              >
                <p>*{error}</p>
              </div>
            </div>
            <p className="Login-card-l-trouble">Having Trouble Signing in?</p>
          </div>
          <div className="Login-oauth-divider"></div>
          <div className="Login-oauth">
            <p>or sign in using:</p>
            <div className="Login-oauth-icons">
              <div
                className="Login-oauth-icon"
                style={loading ? { opacity: "0.4" } : {}}
                onClick={(e) => {
                  if (loading) return;
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <GoogleIcon className="Login-googleIco" />
              </div>
              <div
                className="Login-oauth-icon"
                style={loading ? { opacity: "0.4" } : {}}
                onClick={(e) => {
                  if (loading) return;
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <LinkedinIcon className="Login-inIco" />
              </div>
            </div>
          </div>
        </div>
        <div className="Login-card-r">
          <h1 className="Login-card-r-heading">StochastiPersona</h1>
          <p className="Login-card-r-text">
            Get a 360° view of your personality. Discover your personality with
            our website! Take MBTI, Big Five, and Dark Triad tests to gain deep
            insights into your traits, strengths, and tendencies. Explore
            scientifically-backed assessments and unlock a clearer understanding
            of yourself today!
          </p>
          <div className="Login-card-r-signup"></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
