import { StringMap } from "../utils/stringMap/StringMap";

export default interface QuizResult {
  id: string;
  profile_id: string;
  start_time: Date;
  aggr: number[];
  data: string;
  quizType: string;
}

export function quizResultCopyWith(data: StringMap) {
  let dt: Date;
  try {
    dt = new Date(data.start_time as string);
  } catch (e) {
    dt = new Date();
  }
  const quizResult: QuizResult = {
    id: data.id as string,
    profile_id: data.profile_id as string,
    start_time: dt,
    aggr: data.aggr as number[],
    data: data.data as string,
    quizType: data.quiz_type as string,
  };
  return quizResult;
}
