import { configureStore } from '@reduxjs/toolkit';
import UserSliceReducer from '../../slices/userSlice'
import LoadingSliceReducer from '../../slices/loadingSlice'
import LogoutModalSliceReducer from '../../slices/logoutModalSlice'
import QuizResponseSliceReducer from '../../slices/quizResponseSlice'

export const store = configureStore({
    reducer: {
        user: UserSliceReducer,
        loading: LoadingSliceReducer,
        logoutModal: LogoutModalSliceReducer,
        quizResponse: QuizResponseSliceReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
