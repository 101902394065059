import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { QuizType } from "../../pages/QuizSelect/QuizSelect";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default function RadarChart(props) {
  const data = {
    labels:
      props.quizType === QuizType.OCEAN
        ? [
            "Openness",
            "Conscientiousness",
            "Extraversion",
            "Agreeableness",
            "Neuroticism",
          ]
        : ["Machiavellianism", "Narcissism", "Psychopathy"],
    datasets: [
      {
        label: props.quizType === QuizType.OCEAN ? "OCEAN" : "Dark Triad",
        data: props.data,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      r: {
        pointLabels: {
          font: {
            size: 18, // Increase label font size (e.g., January, February)
          },
          color: "#000",
        },
        ticks: {
          beginAtZero: true,
          font: {
            size: 14, // font size of values in radar chart
          },
        },
      },
    },

    plugins: {
      legend: {
        labels: {
          font: {
            size: 18, // Set label font size for legend
          },
        },
      },
    },
  };

  return <Radar data={data} options={options} />;
}
