import { useNavigate } from "react-router-dom";
import "./Home.css";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="Home">
      <div className="Home-heading">
        <h1 className="Home-heading-text">Get a 360° view of</h1>
        <h1
          className="Home-heading-text gradient-text"
          style={{ marginTop: "-18px" }}
        >
          Your Personality
        </h1>
      </div>
      {/* <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum quia
            id fugiat veritatis culpa ad!
          </p> */}
      <div className="Home-buttons">
        <button
          className="Home-button1"
          onClick={() => {
            navigate("/quizselect");
          }}
        >
          Take Test
        </button>
        <button
          className="Home-button2"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          View Previous Test
        </button>
      </div>
      <div className="Home-cards">
        <div
          className="Home-card"
          // style={{ backgroundColor: "#cc6a4a" }}
        >
          <h1 className="Home-card-heading">OCEAN</h1>
          <p>
            Explore the Big Five traits—openness, conscientiousness,
            extraversion, agreeableness, and neuroticism. Gain a detailed
            understanding of your personality.
          </p>
        </div>
        <div
          className="Home-card"
          // style={{ backgroundColor: "#e0ab43" }}
        >
          <h1 className="Home-card-heading">MBTI</h1>
          <p>
            Uncover your personality type with the MBTI test! Learn how you
            perceive and interact with the world, identify your strengths, and
            improve relationships based on your unique preferences.
          </p>
        </div>
        <div
          className="Home-card"
          // style={{ backgroundColor: "#416346" }}
        >
          <h1 className="Home-card-heading">Dark Triads</h1>
          <p>
            Delve into the Dark Triad traits—narcissism, Machiavellianism, and
            psychopathy. Gain insights into these darker aspects of personality
            and their influence on your behavior.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
