import { StringMap } from "../utils/stringMap/StringMap";

export default interface Profile {
  id: string;
  name: string;
  userName: string;
  email?: string;
  phone?: string;
  role: string;
}

export function profileCopyWith(data: StringMap) {
  const profile: Profile = {
    id: data.id as string,
    name: data.name as string,
    userName: data.username as string,
    email: data.email as string,
    phone: data.phone as string,
    role: data.role as string,
  };
  return profile;
}
